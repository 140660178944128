var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title:
              _vm.$language("개선요청 목록") +
              " [" +
              _vm.popupParam.requestContents +
              "]",
            tableId: "imprRequest",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            selection: "multiple",
            rowKey: "sopImprovementId",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row.actionFlag === "D"
                    ? [
                        _vm.editable
                          ? _c("c-dept", {
                              attrs: {
                                type: "edit",
                                label: "",
                                disabled: !_vm.editable,
                              },
                              on: {
                                setDeptName: (val) => {
                                  ;(props.row["actionDeptName"] = val),
                                    (props.row["actionName"] = val)
                                },
                                datachange: function ($event) {
                                  return _vm.datachange(props)
                                },
                              },
                              model: {
                                value: props.row["actionDeptCd"],
                                callback: function ($$v) {
                                  _vm.$set(props.row, "actionDeptCd", $$v)
                                },
                                expression: "props.row['actionDeptCd']",
                              },
                            })
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(props.row["actionDeptName"]) + " "
                              ),
                            ]),
                      ]
                    : props.row.actionFlag === "V"
                    ? [
                        _c("c-vendor", {
                          attrs: {
                            selfFlag: false,
                            disabled: !_vm.editable,
                            type: "edit",
                            label: "",
                          },
                          on: {
                            dataChange: (val, oldVal, vendor) =>
                              _vm.vendorChange(props, vendor),
                          },
                          model: {
                            value: props.row["actionVendorCd"],
                            callback: function ($$v) {
                              _vm.$set(props.row, "actionVendorCd", $$v)
                            },
                            expression: "props.row['actionVendorCd']",
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "Excel 업로드", icon: "add" },
                        on: { btnClicked: _vm.openExcelUploader },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "외부요청 추가", icon: "add" },
                        on: { btnClicked: _vm.addImprActionVendor },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "내부요청 추가", icon: "add" },
                        on: { btnClicked: _vm.addImprActionDept },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.grid.data,
                          mappingType: "POST",
                          label: "개선요청 저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveImprRequest,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "제외", icon: "remove" },
                        on: { btnClicked: _vm.removeImpr },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }